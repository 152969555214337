<!-- 条形码 -->

<template>
  <div>
    <w-dialog ref="dialogRef" title="条形码" width="30%" top="15vh" :hideFooter="true">
      <div>
        <el-row :gutter="20" v-if="authData.indexOf('n_B10444635DDD2F174E210D9A2C4F') != -1">
          <el-col :span="16">
            <el-input type="Number" v-model="barcode" clearable placeholder="请输入条形码">
            </el-input>
          </el-col>
          <el-col :span="8">
            <el-button type="primary" round @click="onAddBtn">添加</el-button>
          </el-col>
        </el-row>

        <div v-if="barcodeDatas.length == 0" class="empty-text">{{ emptyText }}</div>
        <div class="barcode-content flex" v-else>
          <div class="barcode-text" v-for="(item, index) in barcodeDatas" :key="index" style="position: relative;">
            <div class="circle-num">{{ item.barcode }}</div>
            <div class="circle-close" v-if="authData.indexOf('n_B4EB400846DDACC5915F75E3DA1F') != -1">
              <el-popconfirm :title="`确定要删除${item.barcode}吗?`" @confirm="onClickDelete(item,index)">
                <template #reference>
                  <el-icon size="18">
                    <CircleClose />
                  </el-icon>
                </template>
              </el-popconfirm>
            </div>
          </div>
        </div>
      </div>
    </w-dialog>
  </div>
</template>

<script>
  import { ref, reactive, toRefs, onMounted, computed, watch } from "vue";
  import { CircleClose } from "@element-plus/icons-vue";
  import { StoreApi } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import { useStore } from "vuex";

  export default {
    name: "bar-code",
    components: { CircleClose },
    emits: ["submit"],
    setup() {  // props, { emit }
      const store = useStore();
      const menuTokens = computed(() => store.state.menuToken.menuTokens);  // 权限
      const authData = ref([]);
      watch(
        () => menuTokens.value,
        (data) => {
          if (data.length) {
            authData.value = data;
          }
        },
        {
          deep: true,
          immediate: true,
        }
      );
      // 页面数据
      const state = reactive({
        datas: "",  // 当前行商品数据
        pom_id: "",  //  门店id
        barcode: "",  // 商品条形码
        barcodeDatas: [],  // 条形码列表
        emptyText: "暂无条形码数据！",  // 数据为空显示
      });
      onMounted(() => {

      });
      // 对话框对象
      const dialogRef = ref(null);
      /**
       * 
       * 显示对话框
       * 
       * */
      function openDialog(row, pom_id) {
        state.barcode = "";
        state.datas = row;
        state.pom_id = pom_id;
        getBarcode();
        dialogRef.value.show();
      }
      /**
       * 
       * 关闭对话框
       * 
       * */
      function closeDialog() {
        dialogRef.value.close();
      }
      /**
       * 
       * 获取条形码列表
       * 
       * */
      function getBarcode() {
        StoreApi.getBarcode({
          pom_id: state.pom_id,
          goods_id: state.datas.id,
        }).then((res) => {
          if (res.Code === 200) {
            state.barcodeDatas = res.Data.list;
          } else {
            let msg = res.Message ? res.Message : "获取失败！";
            state.emptyText = msg;
            ElMessage.error(msg);
          }
        });
      }
      /**
       * 
       * 条码添加按钮
       * 
       * */
      const onAddBtn = () => {
        if (!state.barcode) {
          ElMessage.error("请输入条码！");
          return;
        }
        // 验证条码不能重复
        for (let i = 0; i < state.barcodeDatas.length; i++) {
          if (state.barcodeDatas[i].barcode == state.barcode) {
            ElMessage.error("条码不能重复！");
            return false;
          }
        }
        StoreApi.addBarcode({
          goods_id: state.datas.id,
          pom_id: state.pom_id,
          barcode: state.barcode
        }).then((res) => {
          if (res.Code === 200) {
            getBarcode();
            state.barcode = "";
            ElMessage.success("添加成功！");
            // emit("onConfirm");
          } else {
            let msg = res.Message ? res.Message : "添加失败！";
            ElMessage.error(msg);
          }
        });
      }
      /**
       * 
       * 条码删除图标
       * 
       * */
      const onClickDelete = (item) => {
        StoreApi.delBarcode({ barcode_id: item.barcode_id, pom_id: state.pom_id }).then((res) => {
          if (res.Code === 200) {
            getBarcode();
            ElMessage.success("删除成功！");
          } else {
            let msg = res.Message ? res.Message : "删除失败！";
            ElMessage.error(msg);
          }
        });
      }

      return {
        ...toRefs(state),
        openDialog,
        dialogRef,
        closeDialog,
        onAddBtn,
        onClickDelete,
        getBarcode,
        store,
        menuTokens,
        authData,
      };
    },
  };
</script>

<style lang="scss">
  .barcode-content {
    margin-top: 20px;
    flex-flow: wrap;
  }

  .barcode-text {
    margin-right: 10px;
  }

  .circle-num {
    padding: 10px 16px;
    background: var(--menu-bg-color);
    margin-top: 10px;
    border-radius: 10px;
  }

  .circle-close {
    cursor: pointer;
    position: absolute;
    right: -4px;
    top: 4px;
  }

  .empty-text {
    text-align: center;
    margin: 40px 0;
  }
</style>