<!-- 商品详情 -->

<template>
  <div class="">
    <!-- 商品详情 -->
    <w-dialog ref="dialogRef" title="商品详情" width="60%" top="8vh" @wConfirm="handleSure" :hideFooter="true">
      <div class="product flex-btw">
        <div class="flex">
          <div class="product-title">
            <span>所属店铺：</span>
            <span>{{ storeTitle }}</span>
          </div>

          <el-input clearable class="search-input" v-model.trim="keyword" placeholder="请输入商品名称进行搜索"
            :suffix-icon="Search" @input="getProductDetails"></el-input>
        </div>

        <el-button round type="primary" size="small" @click.prevent="onAddBtn(1)"
          v-if="authData.indexOf('n_507E936B09A29AD144A341EADBB3') != -1">新增商品</el-button>
      </div>
      <div class="common-table">
        <div class="table-wp">
          <el-table :data="tableData" stripe :height="height" style="width: 100%" v-loading="isLoading"
            element-loading-text="数据加载中" :empty-text="emptyText" tooltip-effect="light">
            <el-table-column prop="id" label="商品ID">
              <template #default="scope">
                <span class="table-text">{{ scope.row.id }}</span>
              </template>
            </el-table-column>

            <el-table-column prop="title" label="商品名称" />

            <el-table-column prop="price" label="商品价格（元）">
              <template #default="scope">
                <span class="table-text-blue">{{ scope.row.price }}</span>
              </template>
            </el-table-column>

            <el-table-column prop="unit" label="单位" />
            <el-table-column prop="poc_name" label="所属分类" />

            <el-table-column prop="in_stock" label="库存">
              <template #default="scope">
                <template v-if="authData.indexOf('n_ABEA61B7F6FEFF313CC7C70AEBEE') == -1">
                  <div class="block no-auth-block" @click="noAuthSwitch">
                    {{ scope.row.in_stock }}
                  </div>
                </template>

                <template v-else>
                  <div class="table-text cursor-pointer block" @click="onStockDetails(scope.row)">
                    {{ scope.row.in_stock }}
                  </div>
                </template>
              </template>
            </el-table-column>

            <!-- 1上架 2下架 -->
            <el-table-column prop="goods_status" label="商品状态" :show-overflow-tooltip="true" align="center">
              <template #default="scope">
                <template v-if="authData.indexOf('n_30A0AACEC4A9E82212556B9956E9') == -1">
                  <el-switch v-model="scope.row.goods_status" active-text="上架" inactive-text="下架" :active-value="1"
                    :inactive-value="2" :disabled="true" @click="noAuthSwitch" class="switchStyle">
                  </el-switch>
                </template>
                <template v-else>
                  <el-popconfirm :title="`确定要${ scope.row.goods_status == 1 ? '下架' : '上架' }吗?`"
                    @confirm="switchConfirm(scope.row)">
                    <template #reference>
                      <el-switch v-model="scope.row.goods_status" class="switchStyle" active-text="上架"
                        inactive-text="下架" :before-change="() => false" :active-value="1" :inactive-value="2">
                      </el-switch>
                    </template>
                  </el-popconfirm>
                </template>
              </template>
            </el-table-column>

            <el-table-column prop="goodsFile" label="图片" :show-overflow-tooltip="true" align="center">
              <template #default="scope">
                <el-image style="width: 40px; height: 40px" :src="scope.row.src" :preview-src-list="scope.row.srcs"
                  fit="cover">
                  <template #error>
                    <div class="image-slot">
                      <el-icon>
                        <icon-picture />
                      </el-icon>
                    </div>
                  </template>
                </el-image>
              </template>
            </el-table-column>

            <el-table-column prop="description" label="商品备注" />

            <!-- 按钮操作 -->
            <el-table-column fixed="right" label="操作" width="270">
              <template #default="scope">
                <el-button link type="primary" size="small" @click.prevent="onAddBtn(2,scope.row)"
                  v-if="authData.indexOf('n_FE60C9F64BD1C29C8B912A28A25C') != -1">编辑</el-button>
                <el-button link type="primary" size="small" @click.prevent="onStockBtn(scope.row)"
                  v-if="authData.indexOf('n_8E73C6B023A659A1433E3CD86C95') != -1">库存</el-button>
                <el-button link type="primary" size="small" @click.prevent="onBarCodeBtn(scope.row)"
                  v-if="authData.indexOf('n_7C0598EBA58511FCF5CFBDF1CE11') != -1">条码</el-button>
              </template>
            </el-table-column>
          </el-table>

          <div class="flex">
            <div class="paging">共<span>{{ pagingDatas.total }}</span>条</div>
            <el-pagination v-model="pagingDatas.currentPage" :page-sizes="[20, 50, 100, 200, 500]"
              :page-size="pagingDatas.limit" background layout="sizes, prev, pager, next, jumper"
              :total="pagingDatas.total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
            </el-pagination>
          </div>
        </div>
      </div>
    </w-dialog>


    <!-- 新增、编辑商品 -->
    <w-dialog ref="commoditRef" class="vip-dialog" :title="ruleForm.id ? '编辑商品' : '新增商品'" width="50%" btnWidth="140px"
      top="20vh" :confirmText="ruleForm.id ? '确认编辑' : '确认新增'" @wConfirm="onDetermineBtn">
      <el-form class="add-form" ref="formRef" :model="ruleForm" :rules="rules" labelPosition="top">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="商品名称" prop="title">
              <el-input v-model.trim="ruleForm.title" clearable placeholder="请输入商品名称" maxlength="10"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="商品价格（元）" prop="price">
              <el-input v-model="ruleForm.price" clearable placeholder="请输入商品价格" type="Number" :min="0"
                oninput="if(value<0)value=0"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="单位" prop="unit">
              <el-input v-model.trim="ruleForm.unit" clearable placeholder="请输入商品单位" maxlength="5"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="商品所属分类" prop="poc_id">
              <el-select v-model="ruleForm.poc_id" clearable placeholder="请选择分类">
                <el-option :label="item.poc_name" :value="item.poc_id" v-for="item in classificaOptions"
                  :key="item.poc_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8" v-if="!ruleForm.id">
            <el-form-item label="库存" prop="in_stock">
              <el-input type="Number" v-model="ruleForm.in_stock" clearable placeholder="请输入商品库存数量"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item class="upload" label="选择图片" prop="imgUrl">
              <img-upload :needImg="false" :limit="1" uploadTitle="选择图片" :fileList="fileList" @uploadFile="uploadFile"
                @deleteFile="deleteFile" suggestText="">
              </img-upload>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="description" label="商品备注">
              <el-input v-model.trim="ruleForm.description" type="textarea" rows="3" placeholder="请输入商品备注信息"
                maxlength="30">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>

    <!-- 库存调整详情 -->
    <w-dialog ref="stockRef" title="库存调整详情" width="60%" top="15vh" :hideFooter="true">
      <div class="common-table">
        <div class="table-wp">
          <el-table :data="stockDatas" stripe :height="height" style="width: 100%" v-loading="isLoading"
            element-loading-text="数据加载中" :empty-text="emptyText" tooltip-effect="light">
            <el-table-column prop="u_name" label="调整人">
              <template #default="scope">
                <span class="table-text">{{ scope.row.u_name }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="created_at" label="调整时间" />
            <el-table-column prop="old_stock" label="调整数量">
              <template #default="scope">
                <span class="table-text-blue">{{ scope.row.old_stock }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="in_stock" label="调整后数量">
              <template #default="scope">
                <span class="table-text">{{ scope.row.in_stock }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </w-dialog>

    <!-- 修改库存 -->
    <ModifyInventory ref="inventoryRef" @onConfirm="onConfirm"></ModifyInventory>

    <!-- 条码 -->
    <BarCode ref="barCodeRef"></BarCode>
  </div>
</template>

<script>
  import { ref, reactive, toRefs, onMounted, computed, watch } from "vue";
  import { StoreApi } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import imgUpload from "@/components/img-upload/img-upload.vue";
  import ModifyInventory from "./modify-inventory.vue";
  import BarCode from "./bar-code.vue";
  import { useStore } from "vuex";
  import { Search } from "@element-plus/icons-vue";

  export default {
    name: "product-details",
    components: {
      imgUpload,
      ModifyInventory,
      BarCode
    },
    emits: ["submit"],
    setup(props, { emit }) {
      const store = useStore();
      const menuTokens = computed(() => store.state.menuToken.menuTokens);  // 权限
      const authData = ref([]);
      watch(
        () => menuTokens.value,
        (data) => {
          if (data.length) {
            authData.value = data;
          }
        },
        {
          deep: true,
          immediate: true,
        }
      );
      // 页面数据
      const state = reactive({
        storeTitle: "",  // 标题
        tableData: [],  // 表格数据
        emptyText: "暂无数据！",  // 数据为空显示
        isLoading: false,  // 加载
        height: "calc(100vh - 320px)",
        pagingDatas: {  // 分页
          currentPage: 1,
          total: 0,
          limit: 20,
        },
        pom_id: "",  // 门店id
        // 新增、编辑商品填写内容
        ruleForm: {
          title: "",  // 名称
          price: "",  // 价格
          unit: "",  // 单位
          poc_id: "",  // 分类
          in_stock: "",  // 库存
          file_type: 1,  // 文件类型 1-图片
          cover_files: [],  // 图片
          description: "",  // 备注
        },
        classificaOptions: [],  // 分类选项
        stockDatas: [],  // 库存调整详情
        keyword: "",  // 商品搜索内容
      });
      // 验证内容不为空
      const rules = reactive({
        title: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        price: [{ required: true, message: "请输入商品价格", trigger: "blur" }],
        unit: [{ required: true, message: "请输入商品单位", trigger: "blur" }],
        poc_id: [{ required: true, message: "请选择商品所属分类", trigger: "chaneg" }],
        in_stock: [{ required: true, message: "请输入商品库存数量", trigger: "blur" }],
      });
      // 商品详情
      const dialogRef = ref(null);
      /**
       * 
       * 显示商品详情对话框
       * 
       * */
      function openDialog(row) {
        state.pom_id = row.pom_id;
        getProductDetails();
        dialogRef.value.show();
      }
      function closeDialog() {
        dialogRef.value.close();
      }
      function closeDialogLoading() {
        dialogRef.value.isLoading = false;
      }
      function handleSure() {
        dialogRef.value.isLoading = true;
      }
      /**
       * 
       * 获取在售商品详情数据
       * 
       * */
      const getProductDetails = () => {
        let params = {
          limit: state.pagingDatas.limit,
          page: state.pagingDatas.currentPage,
          pom_id: state.pom_id,
          keyword: state.keyword,
        }
        StoreApi.getGoodsLists(params).then((res) => {
          // console.log("商品详情",params, res);
          if (res.Code === 200) {
            // 图片
            res.Data.list.forEach((item) => {
              item.src = item.goodsFile.file_url;
              item.srcs = [item.goodsFile.file_url];
            })
            // 显示所属店铺-标题
            state.storeTitle = res.Data.pom_name;
            state.tableData = res.Data.list;
            state.pagingDatas.total = res.Data.count;
          } else {
            state.tableData = [];
            let msg = res.Message ? res.Message : "获取分类数据失败！";
            ElMessage.error(msg);
          }
        })
          .catch(() => {
            state.emptyText = "数据获取失败！";
          });
      }
      /**
       * 
       * 获取库存调整详情
       * 
       * */
      const getAdjustmentDetails = (row) => {
        StoreApi.getStocks({ id: row.id }).then((res) => {
          if (res.Code === 200) {
            state.stockDatas = res.Data.list;
          } else {
            let msg = res.Message ? res.Message : "获取分类数据失败！";
            ElMessage.error(msg);
          }
        });
      }
      onMounted(() => {
        // 获取门店分类选项数据
        StoreApi.getClassesOptions().then((res) => {
          if (res.Code === 200) {
            state.classificaOptions = res.Data;
          } else {
            let msg = res.Message ? res.Message : "获取分类数据失败！";
            ElMessage.error(msg);
          }
        });
      });
      // 新增商品
      const commoditRef = ref(null);
      // 表单对象
      const formRef = ref(null);
      /**
       * 
       * 新增、编辑商品按钮
       * 
       * */
      function onAddBtn(t, row) {
        // console.log("新增、编辑商品", t, row);
        fileList.value = [];
        if (t == 1) {
          // 新增商品
          state.ruleForm = {
            title: "",  // 名称
            price: "",  // 价格
            unit: "",  // 单位
            poc_id: "",  // 分类
            in_stock: "",  // 库存
            file_type: 1,  // 文件类型 1-图片
            cover_files: [],  // 图片
            description: "",  // 备注
          }
        } else {
          // 编辑商品
          state.ruleForm = row;
          fileList.value.push({ file_url: row.goodsFile.file_url, key: row.goodsFile.sf_path });
          state.ruleForm.cover_files = [row.goodsFile.sf_path];
          state.ruleForm.file_type = row.goodsFile.sf_type;
        }
        store.dispatch("getQiniuData");
        commoditRef.value.show();
      }
      /** 文件列表 */
      const fileList = ref([]);
      /** 文件上传 回调 */
      const uploadFile = (data) => {
        fileList.value.push({ file_url: data.standard_url, key: data.key });
        state.ruleForm.cover_files.push(data.key);
      };
      /** 文件删除回调 */
      const deleteFile = (data) => {
        let index1 = fileList.value.findIndex((item) => item.key === data.key);
        let index2 = state.ruleForm.cover_files.findIndex((item) => item === data.key);
        fileList.value.splice(index1, 1);
        state.ruleForm.cover_files.splice(index2, 1);
      };
      /**
       * 
       * 新增、编辑商品确定按钮
       * 
       * */
      const onDetermineBtn = () => {
        formRef.value.validate((valid) => {
          if (valid) {
            const data = JSON.parse(JSON.stringify(state.ruleForm));
            if (data.cover_files.length == 0) {
              ElMessage.error("请上传图片！");
              return;
            }
            data.pom_id = state.pom_id;
            let url = "";
            if (data.id) {
              // 编辑
              url = "editGoods";
              data["pom_id"] = state.pom_id;
            } else {
              // 新增
              url = "addGoods";
            }
            commoditRef.value.isLoading = true;
            // console.log("data", data, url);
            StoreApi[url](data).then((res) => {
              commoditRef.value.isLoading = false;
              if (res.Code === 200) {
                ElMessage.success(`商品${data.id ? "修改" : "新增"}成功！`);
                commoditRef.value.close();
                getProductDetails();
                emit("onConfirm");
              } else {
                let msg = res.Message ? res.Message : `商品${data.id ? "修改" : "新增"}失败！`;
                ElMessage.error(msg);
              }
            });
            // emit();
          }
        });
      };
      // 修改库存对象
      const inventoryRef = ref(null);
      /**
       * 
       * 修改库存
       * 
       * */
      function onStockBtn(row) {
        inventoryRef.value.openDialog(row);
      }
      /**
       * 
       * 修改库存确认按钮
       * 
       * */
      const onConfirm = () => {
        getProductDetails();
        inventoryRef.value.closeDialog();
      }
      // 新增商品
      const stockRef = ref(null);
      /**
       * 
       * 查看库存
       * 
       * */
      function onStockDetails(row) {
        getAdjustmentDetails(row);
        stockRef.value.show();
      }
      /**
      *
      * 状态改变
      *
      * */
      const switchConfirm = (row) => {
        let data = {
          id: row.id,
          goods_status: row.goods_status == 1 ? 2 : 1,  // 1正常 2禁用
        };
        StoreApi.updateGoodsStatus(data).then((res) => {
          if (res.Code === 200) {
            ElMessage.success("操作成功！");
            getProductDetails();
          } else {
            let msg = res.Message ? res.Message : "操作失败！";
            ElMessage.error(msg);
          }
        });
      }
      // 修改库存对象
      const barCodeRef = ref(null);
      /**
       * 
       * 条码按钮
       * 
       * */
      const onBarCodeBtn = (row) => {
        barCodeRef.value.openDialog(row, state.pom_id);
      }
      /**
      *
      * 每页数量改变处理
      *
      * */
      const handleSizeChange = (number) => {
        state.pagingDatas.limit = number;
        getProductDetails();
      };
      /**
      *
      * 页码改变处理
      *
      * */
      const handleCurrentChange = (number) => {
        state.pagingDatas.currentPage = number;
        getProductDetails();
      };

      function noAuthSwitch() {
        ElMessage.warning("您没有操作权限！");
      }

      return {
        ...toRefs(state),
        handleSure,
        openDialog,
        dialogRef,
        closeDialog,
        closeDialogLoading,
        switchConfirm,
        onStockDetails,
        handleSizeChange,
        handleCurrentChange,
        onAddBtn,
        commoditRef,
        rules,
        formRef,
        onDetermineBtn,
        stockRef,
        getProductDetails,
        getAdjustmentDetails,
        inventoryRef,
        onStockBtn,
        fileList,
        uploadFile,
        deleteFile,
        onConfirm,
        barCodeRef,
        onBarCodeBtn,
        store,
        menuTokens,
        authData,
        noAuthSwitch,
        Search,
      };
    },
  };
</script>

<style lang="scss">
  .paging {
    margin-top: 15px;
    margin-right: 10px;
    color: var(--el-text-color-regular);
    font-size: 13px;
    line-height: 28px;

    span {
      margin: 0 4px;
    }
  }

  .product {
    margin-bottom: 10px;
  }

  .product-title {
    line-height: 32px;
    font-weight: 600;
    font-size: 15px;
    color: var(--text-color);
    margin-right: 10px;
  }

  .table-text {
    color: var(--text-color);
  }

  .table-text-blue {
    color: var(--theme-color);
  }

  .el-table td.el-table__cell {
    color: var(--text-third-color);
  }

  .switchStyle .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
  }

  .switchStyle .el-switch__label--left {
    z-index: 9;
    left: 6px;
  }

  .switchStyle .el-switch__label--right {
    z-index: 9;
    left: -14px;
  }

  .switchStyle .el-switch__label.is-active {
    display: block;
  }

  .switchStyle.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
  }

  .online-detail-dialog {
    p {
      color: var(--text-third-color);
    }

    .checkbox-container {
      border-bottom: 1px solid var(--search-bg-color);
      margin-bottom: 20px;

      .el-checkbox-group {
        padding: 8px 0;
      }
    }

    .el-dialog__body {
      .net-config-content {
        height: 400px;
        overflow-y: auto;
      }
    }
  }

  .group-filter-content {
    padding: 20px;

    .footer-btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;

      >span {
        padding-left: 20px;
        font-size: 14px;
        cursor: pointer;
      }

      .sure {
        color: var(--theme-color);
      }
    }

    .group-flex {
      display: flex;
      font-size: 14px;
      border-bottom: 1px solid #eee;
      color: var(--text-gray-color);
      margin-top: 10px;
      padding-bottom: 6px;

      .el-date-editor {
        box-sizing: border-box;

        .el-range-input {
          background-color: var(--search-uncheck-bg-color);
        }
      }

      .group-title {
        width: 100px;
        color: var(--text-third-color);
        height: 24px;
        line-height: 24px;
      }

      .date-title {
        height: 40px;
        line-height: 40px;
      }

      .group-item {
        display: flex;
        flex: 1;
        flex-wrap: wrap;

        .active {
          background-color: var(--theme-bg-color);
          color: var(--theme-color);
        }

        >div {
          width: 80px;
          text-align: center;
          height: 26px;
          line-height: 26px;
          margin: 0 4px 4px 0;
          cursor: pointer;
        }
      }
    }

    .group-date {
      border-bottom: 1px solid transparent;

      .separate-date {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .el-date-editor {
          width: 44%;
        }
      }
    }
  }

  .common-table {
    font-family: "Source Han Sans CN";

    .table-wp {
      .el-table {
        border-radius: 4px 4px 0 0;

        thead {
          color: var(--text-color);
        }

        th.el-table__cell {
          background-color: var(--search-bg-color);
          padding: 9px 0;
          font-size: 15px;
        }

        td.el-table__cell {
          padding: 9px 0;

          .cell {
            >.el-button {
              padding: 4px 0;
              min-height: 20px;
              width: 76px;
              background-color: transparent;
              border-radius: 20px;
              border: none;
              color: var(--theme-color);
              font-size: 14px;
              border: 1px solid var(--theme-color);

              &:hover {
                background-color: var(--theme-color);
                color: #fff;
              }
            }

            >.img-wp {
              display: inline-block;
              width: 32px !important;
              height: 32px !important;
              border-radius: 2px;
              margin-right: 8px;
              vertical-align: middle;
              position: relative;
              overflow: hidden;
              cursor: pointer;

              .el-image {
                width: 100% !important;
                height: 100% !important;
              }

              .remark {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.3);
                pointer-events: none;

                .dot {
                  padding-left: 5px;
                  line-height: 16px;

                  span {
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    border-radius: 2px;
                    background-color: #fff;
                    margin-right: 4px;
                  }
                }

                .num {
                  font-size: 10px;
                  color: #fff;
                  text-align: center;
                  line-height: 10px;
                  vertical-align: middle;
                }
              }
            }

            >.block {
              width: 32px;
              height: 32px;
              background-color: var(--theme-color);
              border-radius: 3px;
              line-height: 32px;
              text-align: center;
              color: #fff;
              cursor: pointer;
            }

            >.no-auth-block {
              opacity: 0.6;
              cursor: not-allowed;
            }

            >.preview {
              width: 30px;
              height: 30px;
              line-height: 30px;
              border-radius: 3px;
              background-color: #e8e8e8;
              color: var(--text-gray-color);

              .iconfont {
                font-size: 12px;
                margin-left: 6px;
              }
            }

            >.preview.active {
              background-color: var(--search-bg-color);
              color: var(--theme-color);
              cursor: pointer;
            }

            >.qrcode {
              width: 30px;
              height: 30px;
              line-height: 30px;
              border-radius: 3px;
              background-color: var(--search-bg-color);
              color: var(--theme-color);
              cursor: pointer;
              text-align: center;

              .iconfont {
                font-size: 16px;
              }
            }
          }
        }

        td.el-table__cell.image {
          padding: 5px 0;
        }
      }

      .el-pagination {
        padding: 0;
        margin-top: 15px;
      }

      .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: var(--theme-color);
      }
    }
  }

  .search-input {
    width: 300px;

    .el-input__inner {
      background-color: var(--theme-bg-color) !important;
    }

    ::-webkit-input-placeholder {
      color: var(--theme-color);
    }
  }
</style>