<!-- 修改库存 -->

<template>
  <div class="">
    <w-dialog ref="dialogRef" title="修改库存" width="40%" top="15vh" @wConfirm="onConfirmBtn">
      <div>
        <span class="inventory-title">剩余库存：</span>
        <span class="inventory-quantity">{{datas.in_stock}}</span>
      </div>

      <div class="inventory-content">
        <div class="content-title">修改库存数量</div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-input type="Number" v-model="in_stock" clearable placeholder="请输入修改库存数量" maxlength="3"
              oninput="if(value>999)value=999">
            </el-input>
          </el-col>
        </el-row>
      </div>

      <div class="notes">注：输入正数增加库存，负数则为减少库存</div>
    </w-dialog>
  </div>
</template>

<script>
  import { ref, reactive, toRefs, onMounted, } from "vue";
  import { StoreApi } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import { checkStr } from "@/utils/common.js";

  export default {
    name: "modify-inventory",
    components: {},
    emits: ["submit"],
    setup(props, { emit }) {
      // 页面数据
      const state = reactive({
        in_stock: "",
        datas: ""
      });
      onMounted(() => {

      });
      // 对话框对象
      const dialogRef = ref(null);
      /**
       * 
       * 显示对话框
       * 
       * */
      function openDialog(row) {
        state.datas = row;
        state.in_stock = "";
        dialogRef.value.show();
      }
      /**
       * 
       * 关闭对话框
       * 
       * */
      function closeDialog() {
        dialogRef.value.close();
      }
      /**
       * 
       * 修改库存确认按钮
       * 
       * */
      function onConfirmBtn() {
        if (!state.in_stock) {
          ElMessage.error("请输入数量");
          return;
        }

        if (!checkStr(state.in_stock, "integer")) {
          ElMessage.error("请输入整数！");
          return;
        }

        dialogRef.value.isLoading = true;
        StoreApi.setStocks({ id: state.datas.id, in_stock: state.in_stock }).then((res) => {
          if (res.Code === 200) {
            ElMessage.success("操作成功！");
            emit("onConfirm");
          } else {
            let msg = res.Message ? res.Message : "操作失败！";
            ElMessage.error(msg);
          }
          dialogRef.value.isLoading = false;
        });
      }

      return {
        ...toRefs(state),
        openDialog,
        dialogRef,
        closeDialog,
        onConfirmBtn,
      };
    },
  };
</script>

<style lang="scss">
  .inventory-title {
    font-size: 12px;
  }

  .inventory-quantity {
    font-size: 16px;
    color: var(--theme-color);
  }

  .inventory-content {
    margin-top: 20px;
  }

  .content-title {
    margin-bottom: 5px;
  }

  .notes {
    margin-top: 5px;
    font-size: 10px;
    color: var(--text-third-color);
  }
</style>